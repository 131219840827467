import React from "react";
import Footer from "src/components/Footer_it";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/it/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/it/prodotti/" target="_self">
                      Prodotti
                    </a>
                    <ul>
                      <li>
                        <a href="/it/prodotti/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/moisturising/">Moisturising</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/instantglow/">Instant Glow</a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/it/our-heritage/" target="_self">
                      Storia
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/it/know_the_sun/" target="_self">
                      Conosci il sole
                    </a>
                  </li>
                  {/*<li>
                    <a className="es_header" href="/it/Ma-Dove-Sei-Stata/" target="_self">
                      Ma Dove Sei Stata 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Paesi{" "}
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/legal/legal-notice/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/legal/legal-notice/">UK</a>
                      </li>
                      <li>
                        <a href="/es/legal/aviso-legal/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/legal/aviso_legal/">PT</a>
                      </li>
                      <li>
                        <a href="/it/legal/legal-notice/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/legal/legal-notice/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="index-hero">
              <div id="bgImage">
                <div className="areaInner"></div>
              </div>
            </section>
            <section id="content">
              <div className="innerContent">
                <h2>NOTE LEGALI</h2>
                <p>
                  Tutti i contenuti del presente sito Internet sono proprietà di
                  o controllati da Johnson &amp; Johnson S.p.A. e sono protetti
                  dalle leggi internazionali sul copyright.
                </p>
                <p>
                  È possibile scaricare i contenuti del presente sito solo per
                  uso personale, a scopi non commerciali. Non è comunque
                  consentita alcuna modifica o riproduzione ulteriore dei
                  predetti contenuti. I contenuti non potranno essere copiati o
                  utilizzati in altro modo. I proprietari del presente sito
                  compiranno ogni ragionevole sforzo per includere informazioni
                  aggiornate e accurate su questo sito Web, tuttavia non
                  dichiarano, garantiscono o assicurano la precisione,
                  l’aggiornamento o la completezza delle informazioni fornite. I
                  proprietari di questo sito non saranno responsabili di
                  eventuali danni o pregiudizi derivanti dall'accesso a questo
                  sito internet o dall'impossibilità di accedervi, nonché
                  dall'aver fatto affidamento sulle informazioni fornite in
                  questo sito Web.
                </p>
                <p>
                  Questo sito Web può fornire link o fare riferimenti ad altri
                  siti, tuttavia i proprietari di questo sito sono esenti da
                  responsabilità per i contenuti dei siti di terzi e non saranno
                  responsabili per qualsivoglia danno o lesione derivante dai
                  contenuti in questione. Eventuali link ad altri siti vengono
                  forniti come semplice supporto per gli utenti del presente
                  sito Web.
                </p>
                <p>
                  I marchi commerciali, i marchi di servizio, la ragione
                  sociale, la veste commerciale e i prodotti rappresentati nel
                  presente sito Web sono tutelati in Italia e a livello
                  internazionale. È vietato qualsiasi utilizzo degli stessi
                  senza previa autorizzazione scritta dei proprietari del
                  presente sito, tranne che per identificare i prodotti o i
                  servizi della società.
                </p>
                <p>
                  Tutti i dati di identificazione personale contenuti nelle
                  comunicazioni elettroniche inviate al presente sito Web sono
                  soggette alla Privacy Policy di questo sito e trattate in
                  conformità a tale Privacy Policy. I proprietari di questo sito
                  si riservano il diritto di usare o copiare tutte le altre
                  informazioni contenute nelle predette comunicazioni
                  elettroniche, comprese idee, invenzioni, concetti, tecniche o
                  Know-how in esse divulgate, per qualsivoglia scopo. Tali scopi
                  possono includere la comunicazione a terze parti e/o lo
                  sviluppo, la fabbricazione e/o la commercializzazione di beni
                  o servizi.
                </p>
                <p>
                  Il mittente di qualsiasi comunicazione al presente sito Web o
                  ai proprietari del sito sarà responsabile del contenuto e
                  delle informazioni contenute nella comunicazione in questione,
                  comprese la loro veridicità e accuratezza.
                </p>
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinItalia?fref=ts"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuin_italia/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// Conversion Name: LP/HP Piz buin\n// INSTRUCTIONS \n// The Conversion Tags should be placed at the top of the <BODY> section of the HTML page. \n// In case you want to ensure that the full page loads as a prerequisite for a conversion \n// being recorded, place the tag at the bottom of the page. Note, however, that this may \n// skew the data in the case of slow-loading pages and in general not recommended. \n//\n// NOTE: It is possible to test if the tags are working correctly before campaign launch \n// as follows:  Browse to http://bs.serving-sys.com/Serving/adServer.bs?cn=at, which is \n// a page that lets you set your local machine to 'testing' mode.  In this mode, when \n// visiting a page that includes an conversion tag, a new window will open, showing you \n// the data sent by the conversion tag to the MediaMind servers. \n// \n// END of instructions (These instruction lines can be deleted from the actual HTML)\nvar ebRand = Math.random()+'';\nebRand = ebRand * 1000000;\ndocument.write('<scr'+'ipt src=\"HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;rnd=' + ebRand + '\"></scr' + 'ipt>');\n"
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '\n         <img width="1" height="1" style="border:0" src="HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;ns=1">\n      '
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $("#bgImage img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$("#bgImage img").remove();\n});\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
